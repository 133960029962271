import React, { useEffect, useState } from 'react';
import "./Weather.css";

const Weather = () => {
    const [weatherData, setWeatherData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://api.weatherapi.com/v1/current.json?key=e09c2ba799814465b8565735241202&q=Sunderland&aqi=no");
                const data = await response.json();
                setWeatherData(data);
            } catch (error) {
                console.log("Error fetching weather data:", error);
            }
        };

        fetchData();
    }, []);

    if (!weatherData) {
        return <div>Loading...</div>;
    }

    const { location, current } = weatherData;

    return (
        <div className='details-weather'>
            <div className='weather-day'>
                Today
            </div>
            <div className='weather-degree'>
                <div className='weather-icon'>
                    <img src={current.condition.icon} alt={current.condition.text} />
                </div>
                <div className='weather-c'>{current.temp_c}</div>
            </div>
            <div className='weather'>{current.condition.text}</div>
            <div className='weather-place'>{location.country}, {location.name}</div>
            <div className='weather-date'>{current.last_updated}</div>
            <div className='weather-info'>
                <h1>Feels like {current.feelslike_c}</h1>
            </div>
        </div>
    );
};

export default Weather;