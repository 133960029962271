import React from 'react'
import "./Activities.css"
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import '@splidejs/splide/css';
import { SectionTitle, Activity } from "../../../../components"
import { Container } from 'react-bootstrap'

const Activities = ({ contentData, url }) => {

    const activitesData = contentData.activity_types || [];

  return (
    <div className='section-margin'>
        <Container>
            <SectionTitle title = {contentData.homepage_activity_title_small} styleTitle = {contentData.homepage_activity_title} />
        </Container>
        <div className="activites" id="activities">
            <Splide
              options={{
                type: "loop",
                arrows: false,
                pagination: false,
                gap: "15px",
                drag: "free",
                perPage: 5,
                autoWidth: true,
                autoScroll: {
                    pauseOnHover: false,
                    pauseOnFocus: false,
                    rewind: false,
                    speed: 1,
                    }
                }}
                extensions={{ AutoScroll }}
            >
                {
                    activitesData.map((item, index) => (
                        <SplideSlide key={index}>
                                <a href={`/activity-types/${item.id}`}>
                                <Activity 
                                    title={item.name} 
                                    image={url + item.image} 
                                />
                                </a>
                        </SplideSlide>
                    ))
                }
            </Splide>
            
        </div>
       
    </div>
  )
}

export default Activities