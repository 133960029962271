import React from 'react'
import "./Details.css"
import { Header } from "../../components"
import { CalendarReact, Post } from '../../features/Details'
import { Weather } from "../../components/index"
import { Col, Container, Row } from "react-bootstrap"


const Details = ({ contentData, url, innerPageData }) => {

   
    const recentData = contentData.recent_activities || [];

    return (
        <div>
            <Header title={"Home /"} bold={innerPageData.name} image={contentData?.data?.image_top ? url + contentData?.data?.image_top : require("../../assets/images/header.png") } />
            <Container>
                <h1 className='details-title'>{contentData?.data?.name}</h1>
                <Row>
                    <Col lg={9}>
                        <div 
                            className="details-image"
                            style={{ background: `url(${url + contentData?.data?.image}) no-repeat`, }}
                        >
                        </div>
                        <div 
                            className='details-para' 
                            dangerouslySetInnerHTML={{ __html: contentData?.data?.description }}
                        >
                        </div>
                    </Col>
                    <Col lg={3}>
                        <Weather />
                        <CalendarReact />
                        <h1 className='posts-title'>RECENT POSTS</h1>
                        {
                            recentData.map((item, index) => (
                                <a href={`/activity/${item.id}`} key={index}>
                                    <Post
                                        title={item.name}
                                        image={url + item.image}
                                    />
                                </a>
                              
                            ))
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Details
