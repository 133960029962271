import React, { useState, useEffect } from "react";
import "./Navpages.css";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom"
import earth from "../../assets/images/svg/earth.svg"
import search from "../../assets/images/svg/search.svg"

const Navpages = ({ navbarData, logos, url }) => {

    const [scrollPosition, setScrollPosition] = useState(0);
    const [navbarClass, setNavbarClass] = useState('');
  
    const updateNavbar = () => {
      const windowUrl = window.location.pathname;
      if (windowUrl === '/' || windowUrl === '/home') {
        if (scrollPosition > 30) {
          setNavbarClass('nav-pages');
        } else {
          setNavbarClass('nav-home');
        }
      } else {
        setNavbarClass('nav-pages');
      }
    };
  
    useEffect(() => {
      const handleScroll = () => {
        setScrollPosition(window.pageYOffset);
      };
  
      const handleLocationChange = () => {
        updateNavbar();
      };
  
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('popstate', handleLocationChange);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('popstate', handleLocationChange);
      };
    }, []);
  
    useEffect(() => {
      updateNavbar();
    }, [scrollPosition]);
  
    const renderNavLinks = (items) => {
      return items.map((item) => {
        if (item.children && item.children.length > 0) {
          return (
            <NavDropdown title={item.name} id={`nav-dropdown-${item.id}`} key={item.id}>
              {item.children.map((child) => (
                <NavDropdown.Item key={child.id} href={child.url}>
                  {child.name}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          );
        } else {
          return (
            <Nav.Link key={item.id} href={`/${item.url}`}>
              {item.name}
            </Nav.Link>
          );
        }
      });
    };


    return (
        <Navbar expand="lg" className={`${navbarClass}`}>
            <Container>
                <Navbar.Brand href="#">
                    <a href='/home' className="nav-logo">
                        {/* <h1 className="nav-logo-top">{logos.logo_text_1}</h1>
                        <h1 className="nav-logo-bottom">{logos.logo_text_2}</h1> */}
                        <img src={url + logos.logo} className="nav-logo-img" />
                    </a>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="my-2 my-lg-0"
                    >
                     {renderNavLinks(navbarData)}
                    </Nav>
                    <div className='nav-language'>
                       <img src = { earth } />
                        <a>en</a>
                        {/* <button className='nav-search'>
                            <img src = { search } />
                        </button> */}
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Navpages;
