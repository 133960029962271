import React from 'react'
import "./Footer.css"

const Footer = ({ footerData }) => {
  return (
    <div className='footer'>
        <h1 className='footer-title'>{footerData.footer_copyright}</h1>
    </div>
  )
}

export default Footer