import React from 'react'
import "./Home.css"
import { Hero, Activities, About, Proposal, Places } from '../../features/Home'

const Home = ({ contentData, url }) => {
  return (
    <div>
        <Hero
          contentData={contentData}
          url={url}
        />
        <Activities 
          contentData={contentData}
          url={url}
        />
        <About 
          contentData={contentData}
          url={url}
        />
        <Proposal 
          contentData={contentData}
          url={url}
        />
        <Places 
          contentData={contentData}
          url={url}
        />
    </div>
  )
}

export default Home