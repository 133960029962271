import React, {useState, useEffect, useRef} from 'react'
import "./Hero.css"
import { Weather } from '../../../../components'
import { Col, Container, Row, Modal, Button } from 'react-bootstrap'
import play from "../../../../assets/images/svg/play.svg"


function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
       
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      
        <iframe className='modal-video' src={props.contentData.homepage_hero_video_link}></iframe>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}



const Hero = ({ contentData, url }) => {

  const [modalShow, setModalShow] = React.useState(false);
  const [activePopup, setActivePopup] = useState(null);
  const popupRefs = {
    weather: useRef(null),
    location: useRef(null),
    contact: useRef(null)
    // Add more popup refs if needed
  };

  const handleModalShow = () => {
    setModalShow(true);
  };

  const handleModalHide = () => {
    setModalShow(false);
  };

  const handlePopupClick = (popupName) => {
    setActivePopup(popupName);
  };

  const handleClickOutsidePopup = (event) => {
    for (const popupRef of Object.values(popupRefs)) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setActivePopup(null);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsidePopup);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsidePopup);
    };
  }, []);

  return (
    <div className='hero'>
     {activePopup === 'weather' && (
        <div 
          className="weather-popup" 
          ref={popupRefs.weather}
          data-aos="fade-left"
          data-aos-duration="1200"
          >
          <Weather />
        </div>
      )}
      {activePopup === 'location' && (
        <div className="location-popup" ref={popupRefs.location}>
        
        </div>
      )}
      {activePopup === 'contact' && (
        <div className="contact-popup" ref={popupRefs.contact}>
          
        </div>
      )}
      <div className='hero-info-container'>
        <div data-aos="fade-up" data-aos-duration="1500">
          <div className="hero-info hreo-info-weather" onClick={() => handlePopupClick('weather')}>
            <img src={url + contentData.homepage_hero_weather_icon} />
            <h1 className='hero-info-title'>Weather</h1>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration="1800">
          <div className="hero-info hreo-info-location" onClick={() => handlePopupClick('location')}>
            <img src={url + contentData.homepage_hero_location_icon}/>
            <h1 className='hero-info-title'>Location</h1>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration="2000">
          <div className="hero-info hreo-info-contact" onClick={() => handlePopupClick('contact')}>
            <img src={url + contentData.homepage_hero_contact_icon}/>
            <h1 className='hero-info-title'>Contact</h1>
          </div>
        </div>
      </div>
      <video muted autoPlay loop className='hero-video' src={url + contentData.homepage_hero_video} />
       
      <Container>
        <Row className='hero-content'>
          <h1 
            className='hero-title-top' 
            data-aos="fade-right" 
            data-aos-duration="3000">
            {contentData.homepage_hero_title1}
          </h1>
          <h1 
            className='hero-title-bottom' 
            data-aos="fade-right" 
            data-aos-duration="2800">
            {contentData.homepage_hero_title2}
          </h1>
          <Col xl = {5} lg = {6} className='hero-para' data-aos="fade-right" data-aos-duration="2600"> 
            {contentData.homepage_hero_description}
          </Col>
          <div className="watch-video-container">
            <div data-aos="fade-right" data-aos-duration="2000">
              <a className='hero-link'>Contact us</a>
            </div>
            <div className='watch-video-content'>
              <div className='watch-video' data-aos="fade-right" data-aos-duration="1800">
                <div className='watch-video-big'>
                  <div type='button' className='watch-video-small' onClick={() => setModalShow(true)}>
                    <img src = { play } />
                  </div>
                </div>
              </div>
              <h1 className='watch-video-title' data-aos="fade-right" data-aos-duration="1500">watch video</h1>
            </div>
          </div>

          <MyVerticallyCenteredModal
            contentData={contentData}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </Row>
      </Container>
    </div>
  )
}

export default Hero