import React from 'react'
import "./Header.css"
import { Container } from 'react-bootstrap'

const Header = ( { image, title, bold }) => {
  return (
    <div className='header'>
        <img className='header-img' src={image} />
        <Container>
            <h1 data-aos="fade-right" data-aos-duration="1500" className='header-title'>{title} <span>{bold}</span></h1>
        </Container>
    </div>
  )
}

export default Header