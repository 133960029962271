import React from 'react'
import "./Post.css"

const Post = ({ title, image }) => {
    return (
        <div 
        className='post'
        data-aos="fade-up"
        data-aos-duration="1500"
        >
            <div className='post-image'>
                <img className='post-img' src={image} />
            </div>
            <h1 className='post-title'>{title}</h1>
        </div>
    )
}

export default Post
