import React, { useState, useEffect } from 'react';
import './Places.css';
import { Container } from 'react-bootstrap';
import CountUp from 'react-countup';
import play from "../../../../assets/images/svg/play.svg";
import arrow from "../../../../assets/images/svg/arrow.svg";

const Places = ({ contentData, url }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (isPlaying) {
      const iframe = document.getElementById('video-iframe');
      iframe.setAttribute('src', 'https://www.youtube.com/embed/3eNj0rCgs7o?si=fzncuXVmFOR4MfWJ&controls=0&autoplay=1');
    }
  }, [isPlaying]);

  const handleVideoToggle = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="section-margin">
      <Container>
        <h1 className="places-title" data-aos="fade-up" data-aos-duration="1500">
          Top <span>
            <CountUp
              start={0}
              end={contentData.homepage_top_places_count}
              duration={5}
              onEnd={({ pauseResume, reset, start, update }) => start()}
            />
          </span> Places
        </h1>
        <h1 className="places-secondtitle" data-aos="fade-up" data-aos-duration="1900">to Visit in Sunderland</h1>
      </Container>
      <div className="places-video-container">
        {!isPlaying && (
          <div className="places-video-overlay">
            <button className="places-video-control" onClick={handleVideoToggle}>
              <img className="play-video" src={play} alt="Play" />
            </button>
            <img id="arrow-video" src={arrow} alt="Arrow" />
          </div>
        )}
        <iframe
          style={{ display: isPlaying ? 'block' : 'none' }}
          id="video-iframe"
          width="100%"
          height="100%"
          src={contentData.homepage_top_places_video}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Places;