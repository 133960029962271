import { useState, useEffect } from 'react';
import './App.css';
import { Navpages, Footer } from './layouts';
import { Home, List, Details } from './pages';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { ScrollTop } from "./components/index"

function App() {
  const [loading, setLoading] = useState(true);
  const limit = 6;
  const url = "https://backend.sunderlandcity.co.uk";
  const apiVersion = "/api/v1";
  const pageUrl = window.location.pathname;
  const lastWordIndex = pageUrl.lastIndexOf("/") + 1;
  let pageName = pageUrl.substr(lastWordIndex);
  const [navbarData, setNavbarData] = useState([]);
  const [footerData, setFooterData] = useState({});
  const [logos, setLogos] = useState({});
  const [contentData, setContentData] = useState({});
  const [innerPageData, setInnerPageData] = useState({});
  const [translationsData, setTranslationsData] = useState({});
  const [seoSettingsData, setSeoSettingsData] = useState({});

  useEffect(() => {
    AOS.init({
      once: true
    });
  }, [])

  useEffect(() => {
    const fetchData = async (pageName) => {
      try {
        setLoading(true);
        const apiUrl = `${url}${apiVersion}/content-by-page/${pageName}`;
        const response = await fetch(apiUrl);
        const data = await response.json();
        const navbar = data?.data?.navbar?.data || [];
        const footer = data?.data?.footer || "";
        const content = data?.data?.content || "";
        const innerPage = data?.data?.inner_page || "";
        const translations = data?.data?.translations || "";
        const seoSettings = data?.data?.['seo-settings'] || "";
        setNavbarData(navbar);
        setFooterData(footer);
        setContentData(content);
        setInnerPageData(innerPage);
        setTranslationsData(translations);
        setSeoSettingsData(seoSettings);
        setLogos(data?.data?.logos || "");
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    if (["", "home"].includes(pageName)) {
      pageName = "home";
    } 
    else if (pageUrl.includes("activity-types/")) {
      let activityTypeId = pageUrl.substr(lastWordIndex);
      pageName = `activity-types?type_id=${activityTypeId}`;
    }
    else if (pageUrl.includes("activity/")) {
      let activityId = pageUrl.substr(lastWordIndex);
      pageName = `activities?activity_id=${activityId}`;
    }
    

    fetchData(pageName);
  }, [pageName]);

  useEffect(() => {
    const updateFavicon = () => {
      const link = document.querySelector('link[rel="icon"]');
      link.setAttribute('href', `${url + logos.logo_favicon}`);
    };

  
    const updateTitle = () => {
      const mainTitle = `${seoSettingsData?.main_title || ''} ${seoSettingsData?.separator || ''} ${
        innerPageData?.name || ''
      }`;
      let formattedTitle = '';
    
      formattedTitle = mainTitle.trim() || 'Sunderland';
      document.title = formattedTitle;
    };

    updateFavicon();
    updateTitle();
  }, [logos, pageName, seoSettingsData, innerPageData]);

  return (
    <div className="App">
      <BrowserRouter>
      {loading ?  (
          <div class="sunderland-loading">
             <h2 className='loading-letters'>
                <span>S</span>
                <span>U</span>
                <span>N</span>
                <span>D</span>
                <span>E</span>
                <span>R</span>
                <span>L</span>
                <span>A</span>
                <span>N</span>
                <span>D</span>
                <span>.</span>
                <span>.</span>
              </h2>
          </div>
        ) : (
        <>
          <ScrollTop />
          <Navpages
            logos={logos}
            navbarData={navbarData}
            url={url}
          />
          <Routes>
            <Route index element={
              <Home
                contentData={contentData}
                url={url}
              />
            }/>
            <Route path="/home" element={
              <Home
                contentData={contentData}
                url={url}
              />
            }/>
            <Route path = '/activity-types/:id' element = { 
              <List 
                contentData={contentData}
                url={url}
                limit={limit}
                apiVersion={apiVersion}
                innerPageData={innerPageData}
              /> 
            }/>
            <Route path = '/activity/:id' element = {
              <Details
                contentData={contentData}
                url={url}
                innerPageData={innerPageData}
              /> 
            }/>
          </Routes>
          <Footer
            footerData={footerData}
          /> 
        </>
      )}
      </BrowserRouter>
    </div>
  );
}

export default App;
