import React, { useState, useEffect } from 'react';
import './List.css';
import { Header, Category, Paginations } from '../../components';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const List = ({ contentData, url, apiVersion, limit }) => {
  const { id } = useParams();
  const [activitesData, setActivitesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    fetchDataByPage(currentPage);
  }, []);

  const fetchDataByPage = async (page) => {
    const startIndex = (page - 1) * limit;
    const apiURL = `${url}${apiVersion}/content-by-page/activity-types?start=${startIndex}&limit=${limit}&type_id=${id}`;

    try {
      const response = await axios.get(apiURL);
      const { data, pagination } = response?.data?.data?.content;
      setActivitesData(data);
      setTotalPages(pagination?.metadata?.totalPages);
    } catch (error) {
      console.log('Error fetching services:', error);
      // Handle the error condition accordingly
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchDataByPage(page);
  };

  return (
    <div>
      <Header title={'Home /'} bold={'Activities'} image={require('../../assets/images/header.png')} />
      <Container>
        <Row className='justify-content-center'>
          {activitesData &&
            activitesData.map((item, index) => (
              <Col lg={4} md={6} key={index}>
                <Category
                  image={url + item.image}
                  title={item.name}
                  para={item.description_short}
                  link={'More'}
                  activityId={item.id}
                />
              </Col>
            ))}
        </Row>
        <div className='d-flex justify-content-center mt-5 pt-4'>
          <Paginations currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
        </div>
      </Container>
    </div>
  );
};

export default List;