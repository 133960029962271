import React from 'react'
import "./Activity.css"

const Activity = ({ image, title }) => {
  return (
    <div className='activity' data-aos="zoom-in" data-aos-duration="1500">
        <img className='activity-image' src={ image } />
        <h1 className='activity-title'>{ title }</h1>
        <div className='activity-linear'>
        </div>
    </div>
  )
}

export default Activity