import React from 'react'
import "./Proposal.css"
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/css';
import { SectionTitle, Category } from "../../../../components"
import { Container, Row, Col } from 'react-bootstrap'

const Proposal = ({ contentData, url }) => {

    const activitiesData = contentData.activities || [];

  return (
    <div className='section-margin'>
        <Container>
            <SectionTitle title={"info"} styleTitle={"proposal"} />
            <Row className="propsal-categories">
                <Splide
                    options={{
                        gap: "20px",
                        perPage: 3,
                        arrows: false,
                        pagination: false,
                        breakpoints: {
                            992: {
                                perPage: 2,
                            },
                            576: {
                                perPage: 1,
                            }
                        }
                    }}
                >

                    {
                        activitiesData.map((item, index) => (
                            <SplideSlide key={index}>
                                <Category
                                    activityId={item.id} 
                                    image={url + item.image}
                                    title={item.name}
                                    para={item.description_short}
                                    link={"more"}
                                />
                            </SplideSlide>
                        ))
                    }
                   
                </Splide>
                
            </Row>
        </Container>
    </div>  
  )
}

export default Proposal