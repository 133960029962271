import React from 'react'
import "./Category.css"
import { Link } from "react-router-dom"

const Category = ({ image, title, para, link, activityId }) => {
  return (
    <div className='category' data-aos="zoom-out-right" data-aos-duration="1500">
        <div className="category-image" style={{  background: `url(${image}) no-repeat` }}>
        </div>
        <h1 className='category-title'>{title}</h1>
        <p className='category-para'>{para}</p>
        <a href={`/activity/${activityId}`} className='category-link'>{link}</a>
    </div>
  )
}

export default Category