import React from 'react'
import "./About.css"
import { Col, Container, Row } from 'react-bootstrap'

const About = ({ contentData, url }) => {
  return (
    contentData.homepage_section_2_description && (
      <div className='home-about section-bigger-margin'>
        <Container>
          <Row>
            <Col xl={6}>
              <h1
                className='home-about-title'
                data-aos="fade-up"
                data-aos-duration="1500">
                {contentData.homepage_section_2_title_1}
              </h1>
              <h2
                className='home-about-secondtitle'
                data-aos="fade-up"
                data-aos-duration="1800">
                {contentData.homepage_section_2_title_2}
              </h2>
              <p className='home-about-para' data-aos="fade-up" data-aos-duration="2000">
                {contentData.homepage_section_2_description}
              </p>
            </Col>
          </Row>
        </Container>
        <div
          className='home-about-image'
          data-aos="fade-left"
          data-aos-duration="1800"
          style={{ background: `url(${url + contentData.homepage_section_2_image}) no-repeat` }}
        >
        </div>
      </div>
    )
  )
}

export default About