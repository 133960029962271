import React from 'react'
import "./SectionTitle.css"

const SectionTitle = ({ title, styleTitle }) => {
  return (
    <div className='sectiontitle'>
        <h1 className='section-title-h1' data-aos="fade-right" data-aos-duration="1500">{title}</h1>
        <h2 className='section-title-h2' data-aos="fade-right" data-aos-duration="2000">{styleTitle}</h2>
    </div>
  )
}

export default SectionTitle