import React, { useState } from 'react';
import './CalendarReact.css';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';

const CalendarReact = () => {
  const [value, onChange] = useState(new Date());

  return (
    <div>
      <Calendar value={value} onChange={onChange} />
    </div>
  );
};

export default CalendarReact;